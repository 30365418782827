import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import Seo from 'components/Seo'

import {
  BlogContainer,
  BlogTitle,
  BlogRecentContainer,
  BlogRecentItem,
  BlogRecentItemImage,
  BlogRecentItemTitle,
  BlogRecentItemDate,
  BlogRecentItemDescription,
} from 'components/BlogComponents'

const Blog = () => {
  const { t } = useTranslation('blog')

  return (
    <Layout>
      <Seo title={t('title')} description={t('description')} />
      <BlogContainer>
        <BlogTitle>
          <h1>{t('title')}</h1>
        </BlogTitle>
        <BlogRecentContainer>
          <BlogRecentItem>
            <Link
              to="/blog/posts/post5"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              <BlogRecentItemTitle>
                <h3>{t('recent5')}</h3>
              </BlogRecentItemTitle>

              <BlogRecentItemImage>
                <StaticImage
                  src="./graphics/tongue_twister.jpg"
                  alt="Woman practicing tongue twisters"
                />
              </BlogRecentItemImage>
            </Link>
            <BlogRecentItemDate>
              <p>{t('recent5Date')}</p>
            </BlogRecentItemDate>

            <BlogRecentItemDescription>
              <p>{t('recentDescription5')}</p>
            </BlogRecentItemDescription>
          </BlogRecentItem>
          <BlogRecentItem>
            <Link
              to="/blog/posts/post4"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              <BlogRecentItemTitle>
                <h3>{t('recent4')}</h3>
              </BlogRecentItemTitle>

              <BlogRecentItemImage>
                <StaticImage
                  src="./graphics/homophones.jpg"
                  alt="Woman practicing homophones"
                />
              </BlogRecentItemImage>
            </Link>
            <BlogRecentItemDate>
              <p>{t('recent4Date')}</p>
            </BlogRecentItemDate>

            <BlogRecentItemDescription>
              <p>{t('recentDescription4')}</p>
            </BlogRecentItemDescription>
          </BlogRecentItem>
          <BlogRecentItem>
            <Link
              to="/blog/posts/post3"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              <BlogRecentItemTitle>
                <h3>{t('recent3')}</h3>
              </BlogRecentItemTitle>

              <BlogRecentItemImage>
                <StaticImage
                  src="./graphics/thoughtful.jpg"
                  alt="Woman in thought"
                />
              </BlogRecentItemImage>
            </Link>
            <BlogRecentItemDate>
              <p>{t('recent3Date')}</p>
            </BlogRecentItemDate>

            <BlogRecentItemDescription>
              <p>{t('recentDescription3')}</p>
            </BlogRecentItemDescription>
          </BlogRecentItem>
          <BlogRecentItem>
            <Link
              to="/blog/posts/post2"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              <BlogRecentItemTitle>
                <h3>{t('recent2')}</h3>
              </BlogRecentItemTitle>

              <BlogRecentItemImage>
                <StaticImage
                  src="./graphics/common_words.jpg"
                  alt="Man Practicing Common English Words"
                />
              </BlogRecentItemImage>
            </Link>
            <BlogRecentItemDate>
              <p>{t('recent2Date')}</p>
            </BlogRecentItemDate>

            <BlogRecentItemDescription>
              <p>{t('recentDescription2')}</p>
            </BlogRecentItemDescription>
          </BlogRecentItem>
          <BlogRecentItem>
            <Link
              to="/blog/posts/post1"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              <BlogRecentItemTitle>
                <h3>{t('recent1')}</h3>
              </BlogRecentItemTitle>

              <BlogRecentItemImage>
                <StaticImage
                  src="./graphics/introductions.jpg"
                  alt="Strangers introducing themselves"
                />
              </BlogRecentItemImage>
            </Link>
            <BlogRecentItemDate>
              <p>{t('recent1Date')}</p>
            </BlogRecentItemDate>

            <BlogRecentItemDescription>
              <p>{t('recentDescription1')}</p>
            </BlogRecentItemDescription>
          </BlogRecentItem>
        </BlogRecentContainer>
      </BlogContainer>
    </Layout>
  )
}

export default Blog
